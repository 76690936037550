<template>
    <c-panel>
        <c-grid>
            <c-grid-item class="width-100-all">
                <h1>Frequently Asked Questions</h1>

                <h2 class="mt-5">Orders and Shipping</h2>
                <h3 class="mt-5">How long will my order take?</h3>
                <p>
                    Orders are typically processed and shipped within 3 business days (M-F). Canvas prints may take an
                    additional day or two depending on the current volume of orders. If your order is expected to take
                    longer than this, you will be contacted via email.
                </p>
                <h3 class="mt-5">How are prints shipped?</h3>
                <p>
                    For local orders (in and around Sammamish, WA), prints are hand-delivered by Coho Prints. Orders
                    outside of this area are shipped via UPS based on the shipping method you select at checkout.
                </p>
                <h3 class="mt-5">How are prints packaged?</h3>
                <p>
                    Fine Art / Giclee prints sized 16x20 and under are shipped in bend-resistant chipboard mailers with
                    a protective sleeve. Prints larger than this are shipped in 3" tubes along with instructions for
                    decurling your prints. Canvas prints are shipped in custom built heavy duty packaging. Note that
                    shipping large canvas prints can be very expensive. If this is the case, consider buying your canvas
                    rolled (see the 'Wrap' option on the or+der page) and having it stretched locally.
                </p>
                <h3 class="mt-5">Do you offer local pickup?</h3>
                <p>
                    At this time all orders are delivery-only. All local orders have the option of free delivery. In
                    some special circumstances, exceptions can be made. Contact me at daniel@cohoprints.com if you
                    believe this applies to you.
                </p>

                <h2 class="mt-5 pt-5">Payment and Discounts</h2>
                <h3 class="mt-5">Do you provide discounts for professional artists and photographers?</h3>
                <p>
                    Yes, discounts are available for professionals who plan on making regular or bulk orders. Discounts
                    start at 10% and increase based on the amount you spent. Contact me at daniel@cohoprints.com to
                    apply for a pro discount.
                </p>
                <h3 class="mt-5">Are my credit card details safe?</h3>
                <p>
                    All of your payment information is handled by Stripe, a third party payment service with the highest
                    certifications available in the payments industry. None of your credit card information is stored by
                    Coho Prints.
                </p>
                <h3 class="mt-5">Do you accept returns or provide refunds?</h3>
                <p>
                    Custom prints cannot be returned, but in the event that you are dissatisfied with your print, I will
                    work with you to find a resolution that you are happy with. This may include re-printing, a full or
                    partial refund, or credit towards a future purchase.
                </p>

                <h2 class="mt-5 pt-5">Image Preparation</h2>
                <h3 class="mt-5">Is my image big enough to be printed?</h3>
                <p>
                    In general, you should upload your images with the highest resolution versions you have. The more
                    resolution we have to work with, the better the end result will be. In most cases, images can be
                    enlarged up to our maximum size of 40x60, but there are limits to the techniques and technologies
                    for extremely low resolution files. If a print can't be enlarged to the size you've requested, I'll
                    reach out to you and discuss your options.
                </p>
                <h3 class="mt-5">How should I prepare my images for printing?</h3>
                <p>
                    The most important step you can take is to turn down your screen brightness prior to editing your
                    images. Screens are often set at brightness levels which far exceed what is possible on paper. As a
                    result, your images may appear much darker than expected when printed. Setting your screen somewhere
                    between 25% and 50% brightness will help ensure that this doesn't happen.
                </p>
                <p>
                    If you especially concerned about the colors of your prints, I would also encourage you to calibrate
                    your screen using a device such as the XRite i1Display or ColorMunki. When you edit photos on an
                    uncalibrated screen, the colors you see may not match what is printed. As a result, you may find
                    that the prints are cooler or warmer than expected, or that the colors are shifted. Calibrating your
                    screen will ensure that the colors you are looking at are accurate.
                </p>
                <h3 class="mt-5">Will you be editing my images?</h3>
                <p>
                    A few small tweaks are made to images to ensure that what you see on a calibrated screen matches
                    what is printed. Potential issues like sensor spots or noise are also corrected for. I do not apply
                    stylistic edits such as adding contrast or saturation unless requested.
                </p>
                <h3 class="mt-5">What type of files do you support or recommend?</h3>
                <p>
                    I support JPEG, PNG, and TIFF files. For the best quality, save your images using a lossless file
                    format such as TIFF or PNG. Saving images as JPEG can result in a loss of quality which negatively
                    impact your prints. JPEG images are still the most common I receive and do often result in beautiful
                    prints, but TIFF and PNG are the better options.
                </p>

                <h3 class="mt-5">Do I need to use an ICC profile?</h3>
                <p>
                    Not necessarily. Once I receive your image, I soft-proof it and make adjustments to help match what
                    you would see on a calibrated screen. For discerning artists and photographers who wish to proof the
                    images themselves, ICC profiles are available upon request.
                </p>

                <h2 class="mt-5 pt-5">Process and Equipment</h2>
                <h3 class="mt-5">What kind of printers do you use?</h3>
                <p>
                    I use the Canon imagePROGRAF range of printers, including a PRO-4100 for large format prints and a
                    PRO-1000 for smaller prints. Both printers utilize 11-color pigment inks systems with a chroma
                    optimizer for a super broad color gamut.
                </p>
                <h3 class="mt-5">Which papers are you using?</h3>
                <p>
                    After testing over 50 papers from several high end manufacturers, Hahnemühle was selected as the
                    sole paper manufacturer of Coho Prints. Hahnemühle has been making paper for over 400 years and is
                    unparalleled in their quality. When ordering a print, you can select the question mark next to each
                    paper to see exactly which Hahnemühle paper will be used.
                </p>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>
